<template>
  <div class="wx-page">
    <div class="title">微信扫码支付</div>
    <div class="qecode-wrapper" ref="qrcodeBox">
    </div>
    <div class="pay-num-container">
      <div class="title">应付金额</div>
      <div class="num">￥{{ getTotal  }}</div>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import { wxPay, getWxPayStatus } from '@/api/pay'
export default {
  name: 'WxPage',
  data() {
    return {
      payNum: '10000',
      products: '',
      orderNo: null,
      qrcode: null,
      timer: null
    }
  },
  computed: {
    getTotal() {
      return String(this.payNum).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  },
  mounted() {
    console.log(this.$route.query)
    if(this.$route.query) {
      this.payNum = this.$route.query.amount
      // this.products = this.$route.query.products
      this.orderNo = this.$route.query.orderNo
      this.getQrcode(this.$route.query.qrUrl)
      if(this.orderNo) this.getPayStatus()
    }
  },
  methods: {
    getQrcode (ecodeUrl)  {
      const url = decodeURIComponent(ecodeUrl)
      this.qrcode = new QRCode(this.$refs.qrcodeBox, {
            text: url,
            colorDark : '#000000',
            colorLight : '#ffffff',
            correctLevel : QRCode.CorrectLevel.H
        });
        this.qrcode.clear();
        this.qrcode.makeCode(url)
    },
    async getWxOrderInfo () {
      const res = await wxPay({ products: this.products})
      if(res.code === 200) {
        this.orderNo = res.data.orderNo
        this.getQrcode(res.data.codeUrl)
        this.getPayStatus()
      }
    },
    getPayStatus () {
      if(this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.getOrderStatus()
      }, 3 * 1000)
    },
    async getOrderStatus () {
      const res = await getWxPayStatus(this.orderNo)
        if(res.code === 200 && res.success) {
          this.$message({
            type: 'success',
            message: '支付成功!'
          });
          if(this.timer) {
            clearTimeout(this.timer)
            this.timer = null
          }
          // 跳转支付成功界面
          this.$router.push({
            path: '/payResult',
            query: {
              payType: 'wx',
              orderNo: this.orderNo
            }
          })
        } else {
          this.getPayStatus()
        }
    }
  },
  beforeDestroy() {
    if(this.timer) {
      clearTimeout(this.timer)
      this.timer = null
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
  .wx-page{
    height: 100vh;
    // padding-top: 200px;
    font-size: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #444;
    background-color: #f5f5f5;
    background: url('../Home/components/img/pay/pay-bg.png') no-repeat center/cover;
    &>.title {
      font-family: Alibaba PuHuiTi;
      font-weight: 500;
      font-size: 36px;
      color: #464646;
      margin-bottom: 75px;
      position: relative;
      &::after {
        content: "";
        display: inline-block;
        width: 60px;
        height: 5px;
        background: #464646;
        border-radius: 10px;
        position: absolute;
        bottom: -22px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .qecode-wrapper {
      width: 344px;
      height: 344px;
      background: linear-gradient(92deg, #565AB5 0%, #800A52 100%);
      border-radius: 10px;
      position: relative;
      margin-bottom: 40px;
      overflow: hidden;
      :deep(img) {
        position: absolute;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        border-radius: 10px;
      }
      &::after {
        content: "";
        display: inline-block;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        background: #FFFFFF;
        border-radius: 10px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

    }

    .pay-num-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .title {
        font-family: Alibaba PuHuiTi;
        font-weight: 500;
        font-size: 30px;
        color: #555555;
        margin-block: 15px;
      }
      .num {
        font-family: Alibaba PuHuiTi;
        font-weight: 500;
        font-size: 48px;
        color: #FF00A7;
        text-align: center;
      }
    }
  }
</style>
