// 接口
import axios from '@/axios'
import qs from "qs"
import { baseUrl } from '@/axios/baseUrl'

//  获取机器人以及电量值价格信息
export const robootPriceInfo = (type) => axios.get(`${baseUrl}/queapi/product/list/${type}`)
//  获取订阅机器人价格列表
export const dyrobootPriceInfo = (type, name) => axios.get(`${baseUrl}/queapi/product/by_title?type=${type}&title=${name}`)
// 支付宝支付页
export const aliPayPage = (data) => {
  let params = qs.stringify(data)
  return axios.post(baseUrl + '/queapi/ali-pay/trade/page/pay', params)
}

// 微信支付
export const wxPay = (data) => {
  let params = qs.stringify(data)
  return axios.post(baseUrl + '/queapi/wx-pay/native', params)
}

export const getWxPayStatus = (data) => {
  return axios.get(`${baseUrl}/queapi/order-info/query-order-status/${data}`)
}

export  const getOrderDetail = (data) =>  axios.get(`${baseUrl}/queapi/order-info/detail/${data}`)
